// ENV CONSTANTS
export const { REACT_APP_API_BASE: API_BASE } = process.env;
export const { REACT_APP_NAME: APP_NAME } = process.env;

// Place map key
export const { REACT_APP_PLACE_API_KEY: PLACE_KEY } = process.env;

export const { REACT_APP_STRIPE_KEY: STRIPE_KEY } = process.env;

//Firebase
export const { REACT_APP_FIREBASE_KEY: FIREBASE_KEY } = process.env;
export const { REACT_APP_AUTH_DOMAIN: FIREBASE_AUTH_DOMAIN } = process.env;
export const { REACT_APP_PROJECTID: FIREBASE_PROJECTID } = process.env;
export const { REACT_APP_STORAGE_BUCKET: FIREBASE_STORAGE_BUCKET } = process.env;
export const { REACT_APP_MESSAGE_SENDER_ID: FIREBASE_SENDER_ID } = process.env;
export const { REACT_APP_APP_ID: FIREBASE_APP_ID } = process.env;
export const { REACT_APP_MEASUREMENT_ID: FIREBASE_MEASUREMENT_ID } = process.env;
export const { REACT_APP_VAPID_KEY: FIREBASE_VAPID_KEY } = process.env;



// LOCALSTORAGE KEYNAME
export const LS_USER = `user${APP_NAME}`;
export const LS_AUTHTOKEN = `authToken${APP_NAME}`;
export const SELECTED_RESTAURANT_ID=`selectedRestaurantId`;

export const SIGNUPSTEPFORM = {
  personalDetail: 1,
  emailVerified: 2,
  profilePicture: 3,
  subscription: 4,
};

// TYPES FOR REDUCER
export const LOGIN_S = `LOGIN_S`;
export const LOGIN_F = `LOGIN_F`;

export const CREATE_ACCOUNT_S = `CREATE_ACCOUNT_S`;
export const CREATE_ACCOUNT_F = `CREATE_ACCOUNT_F`;

export const USER_LIST_S = `USER_LIST_S`;
export const USER_LIST_F = `USER_LIST_F`;

export const USER_INFO_S = `USER_INFO_S`;
export const USER_INFO_F = `USER_INFO_F`;

export const VERIFY_OTP_S = `VERIFY_OTP_S`;
export const VERIFY_OTP_F = `VERIFY_OTP_F`;

// API ENDPOINTS
export const API_LOGIN = `venue/login`;

// venues owner
export const API_UPDATE_ACCOUT = `/venue/auth/updateVenueOwner`;
export const API_CREATE_ACCOUNT = `venue/createAccount`;
export const API_FORGOT_PASSOWORD = `venue/forgotPassword`;
export const API_USER_LIST = `admin/userList`;
export const API_USER_INFO = `admin/userInfo`;
export const API_VERIFY_OTP = `venue/auth/verifyOtp`;
export const API_RESEND_OTP = `venue/auth/resendOtp`;
export const API_RESET_PASSOWORD = `/resetPassword`;
export const API_SIGN_OUT = `/venue/auth/signoutVenue`;
export const API_GET_VENUE = `/venue/auth/getVenueOwnerById`;

// venue Restaturant

// export const API_VENUE_ADD_RESTAURANT = `/venue/auth/addRestaurant`
export const API_VENUE_ADD_RESTAURANT = `/venue/auth/updateRestaurant`;
export const API_VENUE_LIST_RESTAURANT = `/venue/auth/listRestaurantsOptimised`;
export const API_VENUE_UPDATE_RESTAURANT = `/venue/auth/updateRestaurant`;
export const API_VENUE_DELETE_RESTAURANT = `/venue/auth/deleteRestaurant`;
export const API_GOAT_HISTORY = `${API_BASE}/venue/auth/getRestaurantGoats`;
export const API_GOAT_DELETE_HISTORY = `${API_BASE}/venue/auth/deleteGoat`;
export const API_DELETE_ADVERTISE = `${API_BASE}/venue/auth/updateBannerFlag`;
export const API_VERIFY_VENUE = `${API_BASE}/venue/auth/verifyRestaurant`;

export const API_VENUE_NAV_BAR = `${API_BASE}/venue/auth/getVenueOwnerNavBar`;

// venuer Restaturant types
export const VENUE_ADD_RESTAURANT_SUCCESS = `VENUE_ADD_RESTAURANT_SUCCESS`;
export const VENUE_UPDATE_RESTAURANT_SUCCESS = `VENUE_UPDATE_RESTAURANT_SUCCESS`;
export const VENUE_LIST_RESTAURANT_SUCCESS = `VENUE_LIST_RESTAURANT_SUCCESS`;

// list CUSINE, items, meal

export const API_CUISINE_LIST = `/venue/auth/listCuisine`;
export const API_FOOD_ITEMS_LIST = `/venue/auth/listFoodItems`;
export const API_MEAL_LIST = `/venue/auth/listMeal`;

// CUSINE, items, meal TYPES

export const CUSINE_SUCCESS = `CUSINE_SUCCESS`;
export const MEAL_SUCCESS = `MEAL_SUCCESS`;
export const FOOD_ITEM_SUCESS = `FOOD_ITEM_SUCESS`;

export const GET_RESTAURANT_MENU_SUCESS = `GET_RESTAURANT_MENU_SUCESS`;

// restaturant menu
export const API_ADD_RESTATURANT_MENU = `/venue/auth/addRestaurantMenu`;
export const API_GET_RESTATURANT_MENU = `/venue/auth/getRestaurantMenu`;
export const API_UPDATE_RESTATURANT_MENU = `/venue/auth/updateRestaurantMenu`;
export const API_RESTAURANT_REQUEST = `/venue/auth/restaurantRequest`;
export const API_NEW_PASSWORD = `/admin/changePassword`;
export const API_RESET_PASSOWORD_USER = `/admin/setPassword`;

// anaylytic module.
export const API_GET_USERS_BY_AGE = `/venue/auth/getUsersByAge`;
export const API_getRestaurantIdAndName = `/venue/auth/getRestaurantIdAndName`;
export const API_getUsersByState = `/venue/auth/getUsersByState`;
export const API_getUsersByCity = `/venue/auth/getUsersByCity`;
export const API_getSearchedCuisines = `/venue/auth/getSearchedCuisines`;
export const API_getUserViewSAnalytics = `/venue/auth/getUserViewsAnalytics`;
export const API_LOWEST_HIGHEST_SEARCH_ANALYTICS = `${API_BASE}/venue/auth/highestLowestSearchAnalytics`;
export const API_GOAT_GIVEN_LOST_ANALYTICS = `${API_BASE}/venue/auth/goatGivenLostAnalytics`

// anylytic types
export const GET_USERS_BY_AGE = `GET_USERS_BY_AGE`;
export const getRestaurantIdAndNameE = `getRestaurantIdAndName`;
export const getUsersByState = `getUsersByState`;
export const getUsersByCity = `getUsersByCity`;
export const getLowestHighestSearchHits = `getLowestHighestSearchHits`;
export const getGoatGivenLost = `getGoatGivenLost`;

//offer/deal management
export const API_DEAL_LIST = `${API_BASE}/venue/auth/listDeal`;
export const API_DEAL_ADD = `${API_BASE}/venue/auth/addDeal`;
export const API_DEAL_EDIT = `${API_BASE}/venue/auth/editDeal`;
export const API_CHANGE_DEAL_STATUS = `${API_BASE}/venue/auth/changeDealStatus`;
export const API_DEAL_READMPTION_HISTORY = `${API_BASE}/venue/auth/getDealRedemptionHistory`;
export const API_GET_DEAL = `${API_BASE}/venue/auth/getDeal`;
export const API_DEAL_DELETE = `${API_BASE}/venue/auth/deleteDeal`;
export const API_REACTIVATE_DEAL = `${API_BASE}/venue/auth/reactivateDeal`;
export const API_CHECK_DEAL_WEEK_AVAILABILITY = `${API_BASE}/venue/auth/checkDealWeekAvailability`;


export const getSearchedCuisines = `getSearchedCuisines`;
export const getfoodSearched = `getfoodSearched`;
export const getmealSearched = `getmealSearched`;

export const getUserViewSAnalytics = `getUserViewSAnalytics`;

// adverstie module
export const API_ADD_Adversite = `/venue/auth/addAdvertise`;
export const API_LIST_Adversite = `/venue/auth/listAdvertise`;
export const API_DELETE_Adversite = `/venue/auth/deleteAdvertise`;
export const API_Goat_Average = `/venue/auth/getAnalyticsGoatAverage`;
export const API_CancelAdvertise = `/venue/auth/changeAdvertiseStatus`;
export const goatAverage = `goatAverage`;

//notification listing

export const API_NOTIFICATION_LISTING = `/venue/auth/getNotificationList`;
export const API_DELETE_ACCOUNT = `/venue/auth/deleteVenueOwner`;

// paymente module
export const API_ADD_CARD = `/venue/auth/addCard`;
export const API_UPDATE_CARD = `/venue/auth/updateCardDetails`;
export const ADD_CARD = `ADD_CARD`;
export const lIST_Adversite = `lIST_Adversite`;
export const API_lISTallCards = `/venue/auth/listAllCards`;
export const LISTcards = `LISTcards`;
export const API_DELETE_CARD = `/venue/auth/deleteCard`;
export const API_GET_CMS_DETAIL = (type) => `${API_BASE}/getCms?type=${type}`;

//Subscription Plan
export const API_GET_SUBSCRIPTION_PLAN = `/venue/auth/getSubscriptionPlan`;
export const API_SUBSCRIBEPLAN = `/venue/auth/subscribePlan`;
export const API_GET_CREDIT_PLAN = `/venue/auth/creditPlans/getAll`;
export const API_BUY_CREDITS = `/venue/auth/creditPlans/buyCredits`;

//communication
export const API_COMMUNICATIONS_LIST = `/venue/auth/communication/list`
export const API_ADD_COMMUNICATION = `/venue/auth/communication/add`
export const API_GET_COMMUNICATION = `/venue/auth/communication/get`
export const API_COMMUNICATION_DELETE = `${API_BASE}/venue/auth/communication/delete`
export const API_REACTIVATE_COMMUNICATION = `${API_BASE}/venue/auth/communication/reactivate`
export const API_CHANGE_COMMUNICATION_STATUS = `${API_BASE}/venue/auth/communication/changeStatus`
export const API_COMMUNICATION_EDIT = `${API_BASE}/venue/auth/communication/update`;
export const API_CHECK_COMMUNICATION_AVAILABILITY = `${API_BASE}/venue/auth/communication/checkAvailability`;


//venue pre-population
export const API_GET_LIST_RESTAURANT_FOR_VENUE = `${API_BASE}/venue/auth/listRestaurantsForVenue`;
export const API_GET_RESTAURANT_DETAILS_FOR_VENUE = `${API_BASE}/venue/auth/getRestaurantDetailsForVenue`;

//Counter Details
export const API_GET_COUNTER_DETAILS = `${API_BASE}/venue/auth/creditPlans/creditCounter`;

//schedule List
export const API_GET_SCHEDULE_LIST = `${API_BASE}/venue/auth/creditPlans/getScheduleList`;

//getALL chain restaurant list
export const API_GET_ALL_CHAIN_RESTAURANT_LIST = `${API_BASE}/venue/auth/getAllVenueChains`
export const API_LIST_CHAINED_RESTAURANTS = `${API_BASE}/venue/auth/listChainedRestaurants`;
export const API_LIST_SINGLE_RESTAURANTS = `${API_BASE}/venue/auth/listSingleRestaurants`