import styled from "styled-components";
import { Modal as AntModal } from "antd";
import { device } from "../../../style/breakpoints";

export const ModalWrapper = styled(AntModal)`
  .ant-modal-wrap {
    z-index: 9999 !important;
  }

  .cancel-btns{
    text-align:center;
    color: #000;
    font-size: 17px;
    font-weight: 600;
    line-height: 26px;
  }

  &.claim-location-modal{
    .ant-modal-footer{
      text-align:center !important;
      margin-top:30px ;
    }
    .ant-modal-content{
      .ant-modal-close{
        display: block;
        margin-left:auto;
        position: relative !important;
        top: 0 !important;
      }
    }
  }

  &.claim-week-modal{
    .card-footer{
      margin-top:45px;
    }
    .bnts-cancel{
      background-color: transparent;
      color: #F68C3D;
      font-size: 17px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.14px;
      box-shadow: none;
      justify-content: end;
      &:hover{
         background-color:transparent !important;
         color: #F68C3D;
        }
    }
  }

  .card-footer{
    .ant-btn.ant-btn-primary{
      // width: 217px !important;
      // height:48px !important ;
      &.not-edit{
        background-color:#FDEEE4;
        color: #F68C3D;
        width: 217px !important;
        height:48px !important ;
        &:hover{
         background-color:#FDEEE4 !important;
        }
      }
      &.edit{
        width: 217px !important;
        height:48px !important ;
      }
    }
  }

  &.daysmodal{
            /* radiobtn */
            .ant-modal-title{
                text-align:center;
                margin-bottom: 21px;
            }
            &.ant-modal {
                width: 306px !important;
            }

            .select-btn{
                width: 100%;
                height: 41px;
                color: #fff;
                background: #F68C3D;
                padding: 8px 10px;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height:24px;
                display: flex;
                align-items:center;
                justify-content:center;
            }

            .time-box{
                margin-bottom: 24px;
                display: flex;
                align-items:center;
                justify-content:center;

                select{
                    border-radius: 4px;
                    border: 1px solid #E5E7F0;
                    background: #F5F7FA;
                    height: 30px;
                    padding: 3px 5px;
                    appearance: auto;
                    width: 53px;
                }
                /* div{
                   padding: 0 20px;
                } */
            }

            .center-align{
                margin-bottom: 24px;
                text-align:center;
            .radio-div{
                width:36px;
                height: 36px;
                margin-right:12px;
            display: inline-block;
            }
            input[type="radio"]{
            visibility: hidden;
            height: 0;
            width: 0;
            }
            label {
            width:100%;
            height: 36px;
            display: inline-block;
            text-align: center;
            cursor: pointer;
            background-color: #F5F7FA;
            color: #424242;
            padding: 7px 6px;
            border-radius: 3px;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            &.active{
                color: #fff;
            }
            }
            input[type="radio"]:checked + label{
            background-color: #F68C3D;
            }
            strong{
            color: #F68C3D;
            }
            .transition{
            transition: all .3s ease-out;
            }
            .heading{
            text-align: center;
            font-size:0.8em;
            
            }
            }
        }

  &.common-modal {
    .ant-modal-content {
      .ant-modal-close {
        /* top: 50px;
        inset-inline-end: 65px; */
        &:hover {
          background-color: transparent;
        }
        /* display: none; */
      }
    }
    .ant-btn.ant-btn-primary {
      padding: 8px 20px;
      font-size: 14px;
    }
    .ant-btn.ant-btn-secondary {
      background-color: #e6e8f0;
      border-radius: 8px;
      font-family: "SF Pro Display";
      font-weight: 600;
      color: var(--black--color);
      padding: 8px 20px;
      font-size: 14px;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        margin-left: 12px;
        max-width: 14px;
      }
      &:hover,
      &:focus {
        background-color: #e6e8f0;
        color: var(--black--color);
      }
      &.disable {
        background-color: #e6e8f0;
        color: var(--black--color);
      }
    }
    &.payment-modal {
      .ant-modal-content {
        padding: 45px 85px;
      }
      .payment-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        .title {
          font-family: "SF Pro Display";
          font-weight: 500;
          font-size: 25px;
          color: #000000;
        }
      }
      .two-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        label {
          font-family: "SF Pro Display";
          font-weight: 500;
          font-size: 15px;
          color: rgb(36, 36, 36);
          display: block;
          width: 100%;
          padding-bottom: 8px;
        }
        .arrow {
          font-family: "SF Pro Display";
          font-weight: 500;
          font-size: 16px;
          color: #000000;
        }
        .ant-form-item {
          width: 40%;
          .ant-input {
            width: 100%;
            text-align: center;
          }
        }
      }
      .ant-modal-footer {
        margin-top: 55px;
      }
      .ant-btn.ant-btn-primary {
        padding: 11px 78px;
        font-size: 15px;
      }
    }
    &.upgrade-plan-modal {
      .ant-modal-content {
        padding: 46px 60px;
      }
      .ant-modal-header {
        margin-bottom: 45px;
        .ant-modal-title {
          color: var(--black--color);
          font-family: "SF Pro Display";
          font-size: 32px;
          font-weight: 600;
          line-height: 26px;
        }
      }
      .ant-modal-body {
        .upgrade-card {
          width: 100%;
          padding: 35px 27px;
          border-radius: 14px;
          border: 1px solid var(--theme--color);
          display: flex;
          align-items: center;
          gap: 17px;
          .cardImage {
            min-width: 60px;
            max-width: 60px;
            min-height: 60px;
            max-height: 60px;
          }
          .upgrade-info {
            width: calc(100% - 60px);
            .info-title {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
            .title {
              color: #242424;
              font-family: "SF Pro Display";
              font-size: 22px;
              font-style: normal;
              font-weight: 600;
              line-height: 23px;
              letter-spacing: -0.408px;
              width: calc(100% - 140px);
            }
            .desc {
              padding-top: 13px;
              color: #646464;
              font-family: "SF Pro Display";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 12px;
              letter-spacing: -0.408px;
            }
            .planTime {
              width: 140px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              gap: 8px;
              .amount {
                color: var(--black--color);
                font-family: "SF Pro Display";
                font-size: 30px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
              }

              .month {
                color: #3c3c3c;
                text-align: center;
                font-family: "SF Pro Display";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
              }
            }
          }
        }
        .existing-card {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 120px;
          padding: 22px 52px;
          border-radius: 8px;
          background: #f5f7f9;
          .card-info {
            .title {
              font-family: "SF Pro Display";
              font-size: 22px;
              font-style: normal;
              font-weight: 600;
              color: #242424;
            }
            .desc {
              font-family: "SF Pro Display";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              color: #353535;
              padding: 3px 0;
            }
            .actionBtn {
              font-family: "SF Pro Display";
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              color: var(--theme--color);
            }
          }
        }
        .addPayment {
          border-radius: 8px;
          background: #f5f7f9;
          height: 120px;
          padding: 22px 52px;
          display: flex;
          align-items: center;
          justify-content: center;
          .addPaymentBtn {
            display: flex;
            align-items: center;
            gap: 14px;
            color: #939393;
          }
        }
      }
      .ant-modal-footer {
        margin-top: 45px;
        .modalAction {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 16px 30px;
          flex-wrap: wrap;
          .upgradeBtn {
            min-height: 48px;
            padding: 11px 24px;
            width: 190px;
            font-size: 15px;
            margin: 0;
          }
        }
      }
    }
    &.payment-method-modal {
      .ant-modal-content {
        padding: 46px 85px;
        .ant-modal-close {
          display: block;
          top: 48px;
          right: 90px;
          &:hover {
            background-color: transparent;
          }
        }
        .ant-modal-header {
          margin-bottom: 35px;
          .ant-modal-title {
            color: var(--black--color);
            font-family: "SF Pro Display";
            font-size: 25px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          }
        }
        .ant-modal-footer {
          margin-top: 35px;
        }
        .addressWrapper {
          margin-top: 26px;
          .billing-detail-title {
            color: rgb(0, 0, 0);
            font-family: "SF Pro Display";
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          }
          .actionBtn {
            margin-top: 37px;
          }
        }
        .actionWrapper {
          display: flex;
          align-items: center;
          gap: 33px;
          .saveBtn {
            width: 190px;
            padding: 11px 24px;
            font-size: 15px;
          }
        }
      }
    }
    &.verification-modal{
      &.remove-closeIcon{
        .ant-modal-close{
          display: none;
        }
      }
      .ant-modal-content {
        padding: 46px 60px;
      }
    .ant-modal-header{
      margin-bottom: 66px;
      .ant-modal-title{
        color: var(--black--color);
        font-family: "SF Pro Display";
        font-size: 32px;
        font-style: normal;
        line-height: 26px;
      }
    }
    .invalid-code-error{
      margin-top: 8px;
      display: flex;
      align-items: center;
      gap: 8px;
      color: #FF3B30;
      font-family: 'SF Pro Display';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
      .verification-footer{
        margin-top: 66px;
        width: 100%;
        justify-content: flex-end;
        .ant-btn.ant-btn-primary{
          height: 48px;
          width: 190px;
          padding: 11px 24px;
        }
      }
    }
    &.claim-location-modal{
      .ant-modal-content{
        padding: 43px 54px;
      }
      .location-card{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 42px 0;
        max-width: 425px;
        margin: 0 auto;
        .card-header{
          picture{
            width: 78px;
            height: 78px;
            display: inline-block;
            svg{
              width: 100%;
              height: 100%;
            }
          }
          .card-header{
            margin-top: 11px;
            color: #000;
            font-family: "SF Pro Display";
            font-size: 32px;
            font-weight: 600;
            line-height: 40px;
          }
        }
        .card-body{
          .card-desc{
            color: #3C3C3C;
            font-family: "SF Pro Display";
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
          }
        }
        .card-footer{
          width: 100%;
          .ant-btn.ant-btn-primary{
            width: 100%;
            height: 56px;
            padding: 11px 24px;
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }
  }

  @media only screen and (${device.lg}) {
    .common-modal {
      &.payment-modal {
        .ant-modal-content {
          padding: 45px 30px;
        }
        .payment-header {
          margin-bottom: 20px;
          .title {
            font-size: 22px;
          }
        }
        .two-input {
          .ant-form-item {
            width: 40%;
            .ant-input {
              width: 100%;
              text-align: center;
            }
          }
        }
        .ant-modal-footer {
          margin-top: 40px;
        }
        .ant-btn.ant-btn-primary {
          padding: 8px 48px;
        }
      }
    }
  }

  @media only screen and (${device.xs}) {
    .common-modal {
      &.upgrade-plan-modal {
        .ant-modal-content {
          padding: 35px 30px;
        }
        .ant-modal-header {
          margin-bottom: 26px;
          .ant-modal-title {
            font-size: 22px;
          }
        }
        .ant-modal-body {
          .upgrade-card {
            flex-direction: column;
            padding: 20px 16px;
            align-items: flex-start;
            .upgrade-info {
              width: 100%;
              .info-title {
                align-items: flex-start;
                flex-direction: column;
                gap: 12px;
              }
              .title {
                width: 100%;
                font-size: 18px;
                line-height: 20px;
              }
              .planTime {
                width: 100%;
                align-items: flex-start;
                flex-direction: column;
                .amount {
                  font-size: 26px;
                }
              }
            }
          }
          .addPayment {
            height: 90px;
            padding: 16px;
            .addPaymentBtn {
              gap: 10px;
            }
          }
        }
        .ant-modal-footer {
          margin-top: 26px;
        }
      }
    }
  }
`;
