import { createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "axios";
import { API_CREATE_ACCOUNT, API_DELETE_ACCOUNT, API_FORGOT_PASSOWORD, API_GET_VENUE, API_LOGIN, API_NOTIFICATION_LISTING, API_RESEND_OTP, API_RESET_PASSOWORD, API_SIGN_OUT, API_UPDATE_ACCOUT, API_VERIFY_OTP, CREATE_ACCOUNT_F, CREATE_ACCOUNT_S, LOGIN_F, LOGIN_S, LS_AUTHTOKEN, LS_USER, SELECTED_RESTAURANT_ID, VERIFY_OTP_F, VERIFY_OTP_S } from "../constants";

const initialState = {
  // Global loader for api 
  isLoading: false,

  // Auth Data
  isLoggedIn: false,
  userData: {},
  venueDetail: {},
};

export const loginAction = (data) => ({
  type: "API",
  payload: {
    url: API_LOGIN,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: LOGIN_S,
      payload: data,
    }),
    error: (data) => ({
      type: LOGIN_F,
      payload: {},
    }),
  },
});


export const logoutAction = (data) => ({
  type: "API",
  payload: {
    url: API_SIGN_OUT,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: "LOGOUT_SUCCESS",
      payload: data,
    }),
    error: (data) => ({
      type: "LOGOUT_FAIL",
      payload: {},
    }),
  },
});

export const createAccount = (data) => ({
  type: "API",
  payload: {
    url: API_CREATE_ACCOUNT,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: CREATE_ACCOUNT_S,
      payload: data,
    }),
    error: (data) => ({
      type: CREATE_ACCOUNT_F,
      payload: {},
    }),
  },
});
export const updateAccount = (data) => ({
  type: "API",
  payload: {
    url: API_UPDATE_ACCOUT,
    method: "PATCH",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: 'updateAccount/success',
      payload: data,
    }),
    error: (data) => ({
      type: 'updateAccount/fail',
      payload: {},
    }),
  },
});
export const getVenueDetail = (data) => ({
  type: 'API',
  payload: {
    url: API_GET_VENUE,
    method: 'GET',
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: 'getVenueDetail/success',
      payload: data,
    }),
    error: (data) => ({
      type: 'getVenueDetail/fail',
      payload: {},
    }),
  },
})

export const RESEND_OTP = (data) => ({
  type: "API",
  payload: {
    url: API_RESEND_OTP,
    method: "POST",
    data: data,
    hideLoader: false
  },
});
export const VERIFY_ACCOUNT = (data) => ({
  type: "API",
  payload: {
    url: API_VERIFY_OTP,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: VERIFY_OTP_S,
      payload: data,
    }),
    error: (data) => ({
      type: VERIFY_OTP_F,
      payload: {},
    }),
  },
});

export const DELETE_ACCOUNT = (data) => ({
  type: "API",
  payload: {
    url: API_DELETE_ACCOUNT,
    method: "POST",
    data: data,
    hideLoader: false
  },
});


export const GET_STATES = () => ({
  type: "API",
  payload: {
    url: `/states`,
    method: "GET",
    hideLoader: false
  },
})


export const ForgotPassword_API = (data) => ({
  type: "API",
  payload: {
    url: API_FORGOT_PASSOWORD,
    method: "POST",
    data: data,
    hideLoader: false
  }
});

export const ResetPassword_API = (data) => ({
  type: "API",
  payload: {
    url: API_RESET_PASSOWORD,
    method: "POST",
    data: data,
    hideLoader: false
  }
});
export const NOTIFICATION_LISTING = (data) => ({
  type: "API",
  payload: {
    url: API_NOTIFICATION_LISTING,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: "notificationData",
      payload: data,
    })
  },
});

// Reducer
const loginSlice = createSlice({
  name: "login",
  initialState: initialState,
  reducers: {
    loaderChange: (state, payload) => {
      state.isLoading = payload.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(LOGIN_S, (state, action) => {
      // Default header for auth
      axios.defaults.headers.common["Authorization"] = action?.payload?.data?.accessToken;
      localStorage.setItem(LS_AUTHTOKEN, JSON.stringify(action?.payload?.data?.accessToken));
      localStorage.setItem(LS_USER, JSON.stringify(action?.payload?.data));
      state.otpVerifyStatus = action?.payload?.data?.signupStep
      state.userData = action?.payload.data;
      state.isLoggedIn = true;
    });
    builder.addCase(LOGIN_F, (state, action) => {
      // remove items on logout
      delete axios.defaults.headers.common['Authorization']
      localStorage.removeItem(LS_AUTHTOKEN);
      localStorage.removeItem(LS_USER);
      localStorage.removeItem(SELECTED_RESTAURANT_ID)

      state.userData = {};
      state.isLoggedIn = false;
    });
    builder.addCase(CREATE_ACCOUNT_S, (state, action) => {
      // remove items on logout
      // delete axios.defaults.headers.common['Authorization']
      state.userData = action.payload.data
      state.isLoggedIn = true;

      // store userInfo and token in local storage.
      localStorage.setItem(LS_AUTHTOKEN, JSON.stringify(action.payload.data.accessToken));
      localStorage.setItem(LS_USER, JSON.stringify(action.payload.data));
    });
    builder.addCase(CREATE_ACCOUNT_F, (state, action) => {
      state.userData = {};
      state.isLoggedIn = false;
    });
    builder.addCase(VERIFY_OTP_S, (state, action) => {

    });
    builder.addCase(VERIFY_OTP_F, (state, action) => {

    });
    builder.addCase('getVenueDetail/success', (state, action) => {
      state.venueDetail = action.payload.data
    })
    builder.addCase('getVenueDetail/fail', (state, action) => {
      state.venueDetail = {}
      state.isLoggedIn = false
    })
  },
});

export const { loaderChange } = loginSlice.actions;
export default loginSlice.reducer;
