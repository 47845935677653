import React, { lazy } from "react";
import { Routes, Route, Navigate, Outlet, useLocation, } from "react-router-dom";
import AuthGuard from "../components/auth";
import NewPassword from "../pages/NewPassword";
import UserNewPassword from "../pages/NewPassword/UserNewPassword";
import AddCommunications from "../pages/Communications/addCommunication";

const Login = lazy(() => import("../pages/Login"));
const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));
const ResetPassword = lazy(() => import("../pages/ResetPassword"));
const ChangePassword = lazy(() => import("../pages/ChangePassword"));
const CreateAccount = lazy(() => import("../pages/CreateAccount"));
const OtpCode = lazy(() => import("../pages/OtpCode"));
const SetPassword = lazy(() => import("../pages/SetPassword"));

const PageLayout = lazy(() => import("../pages/Layout"));
// const Dashboard = lazy(() => import("../pages/Dashboard"));

const VenueInfo = lazy(() => import("../pages/Locations"));
const GoatHistory = lazy(() => import("../pages/Locations/GoatHistory"));

const AddVenueInformation = lazy(() => import("../pages/Locations/AddVenueInformation"));
const AddVenueMenu = lazy(() => import("../pages/Locations/AddVenueMenu"));
const EditVenueInformation = lazy(() => import("../pages/Locations/EditVenueInformation"));
const EditVenueMenu = lazy(() => import("../pages/Locations/EditVenueMenu"));

const Analytics = lazy(() => import("../pages/Analytics"));

const Deals = lazy(() => import("../pages/Deals"));
const RedemptionHistory = lazy(() => import("../pages/Deals/RedemptionHistory"));
const AddDeal = lazy(() => import("../pages/Deals/AddDeal"));
const EditDeal = lazy(() => import("../pages/Deals/EditDeal"))
const ViewDeal = lazy(() => import("../pages/Deals/viewDeal"))

const Advertising = lazy(() => import("../pages/Advertising"));
const SponsoredListing = lazy(() => import("../pages/Advertising/SponsoredListing"));
const SponsoredStepper = lazy(() => import("../pages/Advertising/SponsoredStepper"));

const MyAccount = lazy(() => import("../pages/MyAccount"));
const Notifications = lazy(() => import("../pages/MyAccount/Notifications"));

const Schedule = lazy(() => import("../pages/Schedule"));
const AddCredit = lazy(() => import("../pages/Schedule/addCredit"));

const Communications = lazy(() => import("../pages/Communications"));
const ViewCommunication = lazy(() => import("../pages/Communications/view"));
const EditCommunication = lazy(() => import("../pages/Communications/EditCommunication"));

const Resources = lazy(() => import("../pages/Resources"));


const Routing = () => {

  const location = useLocation()

  if (location.pathname.startsWith('/restaurants')) {
    // Redirect to the external URL
    window.location.href = 'https://www.mygoatgrub.com/';
    return null; // Render nothing else since we're redirecting
  }


  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset" element={<ResetPassword />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/new-passwordreset/:id" element={<NewPassword />} />
      <Route path="/setuserpassword/:id" element={<UserNewPassword />} />
      <Route path="/create-account" element={<CreateAccount />} />
      <Route path="/otp-code" element={<OtpCode />} />
      <Route path="/claim/:restaurantId" element={<Login />} />
      <Route path="/set-password" element={<SetPassword />} />


      <Route path="/" element={<AuthGuard><PageLayout /></AuthGuard>}>
        <Route index path="/venue-info" element={<VenueInfo />} />
        <Route index path="/venue-info/history" element={<GoatHistory />} />
        <Route index path="/venue-info/add-venue-information" element={<AddVenueInformation />} />
        <Route index path="/venue-info/add-venue-menu" element={<AddVenueMenu />} />
        <Route index path="/venue-info/edit-venue-information" element={<EditVenueInformation />} />
        <Route index path="/venue-info/edit-venue-menu" element={<EditVenueMenu />} />

        <Route index path="/analytics" element={<Analytics />} />

        <Route index path="/deal/:type" element={<Deals />} />
        <Route index path="/deal/:type/history/:id" element={<RedemptionHistory />} />
        <Route index path="/deal/add" element={<AddDeal />} />
        <Route index path="/deal/:type/edit/:id" element={<EditDeal />} />
        <Route index path="/deal/:type/view/:id" element={<ViewDeal />} />


        <Route index path="/advertising" element={<Advertising />} />
        <Route index path="/advertising/sponsored-listing" element={<SponsoredListing />} />
        <Route index path="/advertising/sponsored-stepper" element={<SponsoredStepper />} />

        <Route index path="my-account/:activetab" element={<MyAccount />} />
        <Route index path="/notifications" element={<Notifications />} />

        <Route path="/" element={<Navigate replace to="/venue-info" />} />

        <Route index path="/schedule" element={<Schedule />} />
        <Route index path="/schedule/add-credit" element={<AddCredit />} />
        <Route index path="/schedule/add-deal" element={<AddDeal isNext={true} />} />
        <Route index path="/schedule/deal/view/:id" element={<ViewDeal isSchedule={true} />} />
        <Route index path="/schedule/communications/view/:id" element={<ViewCommunication isSchedule={true} />} />
        <Route index path="/schedule/add-communications" element={<AddCommunications isNext={true} />} />

        <Route index path="/communications/:type" element={<Communications />} />
        <Route index path="/communications/add" element={<AddCommunications />} />
        <Route index path="/communications/:type/view/:id" element={<ViewCommunication />} />
        <Route index path="/communications/:type/edit/:id" element={<EditCommunication />} />

        <Route index path="/resources" element={<Resources />} />

      </Route>

      <Route path="*" element={<Navigate replace to="/venue-info" />} />

      <Route
        path="*"
        element={
          location.pathname.startsWith('/.well-known') ? null : (
            <Navigate replace to="/venue-info" />
          )
        }
      />
    </Routes>
  );
};

export default Routing;
